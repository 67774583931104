@import "src/media";

.courses-list {
    margin: 0 0 38px;

    .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 2%;
        grid-row-gap: 4%;

        @media (max-width: $desktop-l) {
            grid-column-gap: 19px;
            grid-row-gap: 16px;
        }

        @media (max-width: $desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 18px;
            grid-row-gap: 20px;
        }

        @media (max-width: $tablet) {
            grid-template-columns: 100%;
        }

        @media (max-width: $mobile-l) {
            grid-row-gap: 12px;
        }



        .item {
            background: #fff;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            padding: 0 0 12px;
            -webkit-transition: linear box-shadow 250ms;
            -moz-transition: linear box-shadow 250ms;
            -o-transition: linear box-shadow 250ms;
            transition: linear box-shadow 250ms;

            @media (max-width: $tablet) {
                padding: 0 0 24px;
            }

            .image-container {
                position: relative;
                overflow: hidden;
                border-radius: 6px 6px 0 0;

                @media (max-width: $tablet) {
                    margin-bottom: 5px;
                    padding: 12px 12px 0;
                }

                .image {
                    width: 100%;
                    height: 156px;
                    object-fit: cover;
                    transition: transform .25s;

                    @media (min-width: 1480px) {
                        height: 200px;
                    }

                    @media (max-width: $tablet) {
                        border-radius: 8px;
                    }
                }

                .item-data {
                    width: 100%;
                    position: absolute;
                    display: flex;
                    justify-content: end;
                    height: 28px;
                    padding: 0 16px;
                    top: 16px;

                    @media (max-width: $tablet) {
                        left: 0;
                        padding: 8px 28px 0 0;
                        height: 36px;
                    }

                    .time {
                        padding: 4px 8px;
                        display: flex;
                        align-items: center;

                        background: #E85B48;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;

                        .icon {
                            display: flex;
                            align-items: center;
                            margin: 0 4px 0 0;
                        }

                        .minutes {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            /* identical to box height */

                            display: flex;
                            align-items: center;
                            letter-spacing: 0.03em;

                            /* Mecaso / White */

                            color: #fff;
                        }
                    }

                    .status-of-course {
                        padding: 4px 12px;
                        margin: 0 8px 0 0;
                        display: flex;
                        align-items: center;
                        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        border-radius: 6px;


                        &.completed {
                            background: #1A9D86;
                        }

                        &.hold {
                            background: #19A3DE;
                        }

                        &.in-progress {
                            background: #1A89A0;
                        }

                        .title-status {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 160.5%;
                            /* or 22px */

                            letter-spacing: 0.01em;

                            /* Mecaso / White */

                            color: #FFFFFF;

                        }

                        .icon {
                            display: flex;
                            align-items: center;
                            margin: 0 0 0 10px;
                        }


                    }
                }


            }

            .text-container {
                padding: 0 12px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                margin: 0 0 10px;

                @media (max-width: $tablet) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                .title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 125%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    color: #05060B;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;

                    @media (max-width: $tablet) {
                        margin-bottom: 12px;
                    }
                }

                .lessons {
                    padding: 4px 6px;

                    background: rgba(83, 166, 131, 0.1);
                    border-radius: 6px;

                    display: flex;
                    align-items: center;

                    .icon {
                        display: flex;
                        align-items: center;
                        margin: 0 4px 0 0;
                    }

                    .count-lessons {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.03em;
                        color: #034766;
                    }
                }
            }

            .description {
                padding: 0 12px;
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: #5A5A5A;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;

                @media (max-width: $tablet) {
                    -webkit-line-clamp: 3;
                }
            }

            &:hover {
                box-shadow: 0 9px 10px rgba(15, 62, 83, 0.15);

                @media (hover: hover) {
                    .image {
                        transform: scale(1.02);
                    }
                }
            }
        }
    }

}