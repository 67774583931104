@import "src/media";

.quiz {
    position: absolute;
    left: 0;
    display: flex;
    width: 100%;
    min-height: calc(100% - 100px);
    padding: 32px 80px 32px;
    background-color: var(--emerald);

    @media (max-width: $tablet-l) {
        min-height: calc(100% - 59px);
        padding: 32px 5%;
    }

    &__card {
        width: 100%;
        padding: 35px 0 125px;
        border-radius: 12px;
        background-color: white;
        color: var(--black);

        &--complete {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    &__title {
        position: relative;
        padding: 0 220px;
        margin-bottom: 71px;
        font-weight: 600;
        text-align: center;
        font-size: 24px;
        line-height: 125%;

        @media (max-width: $tablet-l) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 60px;
            padding: 0 24px;
            text-align: left;
        }

        &--complete {
            margin-bottom: 52px;
        }
    }

    &__complete-icon {
        display: block;
        margin: 0 auto 56px;
        flex-shrink: 0;
    }

    &__complete-btn {
        display: inline-flex;
        min-width: 200px;
        margin: 0 auto;
        padding: 8px 50px;
    }

    &__exit {
        position: absolute;
        right: 32px;
        top: -5px;
        display: flex;
        align-items: center;
        padding: 16px 23px 16px 24px;
        height: 40px;
        border: 1px solid var(--emerald);
        border-radius: 100px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: var(--emerald);
        transition: all .25s;

        @media (max-width: $tablet-l) {
            position: static;
        }

        &:hover {
            background-color: rgba(230, 243, 241, 0.3);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 220px;
    }

    &__question {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        letter-spacing: 0.015em;
    }


    &__textarea {
        width: 100%;
        min-height: 160px;
        padding: 14px 18px;
        border: 1px solid var(--grey);
        border-radius: 6px;
        outline: 0;
        resize: none;
        transition: border-color .25s;

        &:focus {
            border-color: var(--marine);
        }

        &::placeholder {
            color: rgba(201, 201, 201, 1);
        }
    }

    &__select {
        width: 520px;
        max-width: 100%;
    }

    .nav-buttons {
        width: 100%;
        margin-top: 56px;

        @media (max-width: $tablet) {
            flex-direction: column;
            align-items: flex-start;

            .button {
                margin: 0 0 16px;

                &:last-child {
                    margin: 0;
                }
            }
        }
        
        @media (max-width: 480px) {
            .button {
                width: 100%;
            }
        }
    }

    .c-input + .c-input {
        margin-top: 16px;
    }

    .loading-wrap {
        height: auto;

        .loading div {
            background: white;
        }
    }


    @media (max-width: $desktop) {
        &__content{
            padding: 0 24px;
        }
    }
}