@import "src/media";

.page-dashboard {
  display: flex;
  justify-content: space-between;
  margin-right: -40px;
  height: 100%;

  .tabs-wrap {
    position: relative;
    width: auto;
    flex-grow: 1;
    background: #F9F9F9;
    padding: 50px 32px 0;

    .title-block {
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        margin: 0 0 34px;
        color: #05060B;
      }
    }
  }

  .sidebar {
    padding: 16px 40px 0 24px;
    min-width: 380px;
    width: 26%;
    background-color: white;

    .my-points {
      background: #fff;
      box-shadow: 0 2px 19px rgba(50, 50, 71, 0.05);
      border-radius: 12px;
      margin: 0 0 15px;
      display: flex;
      flex-direction: column;

      .active-learning {
        display: flex;
        padding: 12px 24px 12px 12px;
        margin: 0 16px 24px;
        background: #1A89A0;
        align-items: center;
        box-shadow: 0 8px 8px rgba(50, 50, 71, 0.08), 0 8px 16px rgba(50, 50, 71, 0.06);
        border-radius: 6px;

        .icon {
          margin: 0 12px 0 0;
          align-items: center;
          display: flex;
        }

        .info {
          font-size: 12px;
          line-height: 125%;
          font-weight: 400;
          color: #fff;

          .time,
          .count-points {
            font-weight: 700;
            font-size: 14px;
            line-height: 125%;
            letter-spacing: 0.015em;

            @media (max-width: $tablet) {
              font-size: 12px;
            }
          }
        }
      }

      .schedule {
        position: relative;
        padding: 0 16px;

        .caption {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;

          color: #05060B;
          margin: 0 0 26px;
        }

        .chart-wrap {

          position: relative;

          height: 48px;

          .success {
            position: absolute;
            background: #1A89A0;
            z-index: 15;
            height: 6px;
            border-radius: 100px;

            .icon {
              width: 26px;
              height: 26px;

              position: absolute;
              top: -10px;
              right: 0px;

              svg {
                width: 26px;
                height: 26px;
              }
            }
          }

          .point-one {
            z-index: 10;
            background: #E6F3F1;

            position: absolute;

            height: 6px;
            border-radius: 100px;
            top: 0;

            .icon {
              position: absolute;
              right: 0;
              top: 0;

              svg {
                position: absolute;
                top: 0;
                right: 0;
              }

              .point-text {
                top: 14px;
                right: -50%;
                position: relative;
              }
            }
          }

          .point-two {
            position: absolute;
            width: 100%;
            height: 6px;
            border-radius: 100px;
            top: 0;
            z-index: 5;
            background: rgba(230, 243, 241, 0.3);

            .icon {
              position: absolute;
              right: 0;
              top: 0;

              svg {
                position: absolute;
                top: 0;
                right: 0;
              }

              .point-text {
                top: 14px;
                position: relative;
              }
            }
          }

          .point-text {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #034766;
          }

          .learning-time {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            position: absolute;
            top: -24px;
            right: 0;
            letter-spacing: 0.03em;
            color: #5A5A5A;
          }
        }

      }
    }

    .upcoming-courses {
      background: #fff;
      box-shadow: 0 2px 19px rgba(50, 50, 71, 0.05);
      border-radius: 12px;


      .upcoming-items {
        padding: 10px 0;

        .item {
          display: flex;
          margin: 12px 0;
          padding: 0 12px;

          .date {
            min-width: 56px;
            height: 64px;
            background: #034766;
            border-radius: 6px;
            margin: 0 12px 0 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .number {
              font-weight: 500;
              font-size: 20px;
              line-height: 125%;
              color: #fff;
            }

            .day-of-week {
              font-weight: 300;
              font-size: 12px;
              line-height: 125%;
              color: #fff;
            }
          }

          .info {
            .title {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: #05060B;
              margin: 0 0 4px 0px;
            }

            .full-date {
              display: flex;
              align-items: center;

              .icon {
                display: flex;
                align-items: center;
              }

              .calendar-date {
                font-size: 14px;
                line-height: 160.5%;

                letter-spacing: 0.01em;
                color: #5A5A5A;
                margin: 0 0 0 4px;
              }
            }
          }

        }
      }

    }

    .section-head {
      border-bottom: 0.5px solid #E6F3F1;
      padding: 0 16px;
      display: flex;
      align-items: center;
      height: 70px;

      .section-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: #05060B;
        display: flex;
        align-items: center;

        .icon {
          margin: 0 12px 0 0;
        }
      }
    }

    @media (max-width: $desktop) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: transparent;

      .my-points{
        width: calc(50% - 8px);
      }

      .upcoming-courses{
        width: calc(50% - 8px);
      }
    }

    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding: 12px 5% 0;

      .my-points{
        width: 100%;
      }

      .upcoming-courses{
        width: 100%;
      }
    }
  }

  @media (max-width: $desktop-l) {
    .sidebar {
      min-width: 325px;
    }
  }

  @media (max-width: $desktop) {
    margin-right: 0;
    flex-direction: column-reverse;

    .sidebar {
      .section-head {
        .section-title {
          font-weight: 600;
          font-size: 18px;
        }
      }

      .my-points {
        .level {
          font-size: 32px;
        }
      }
    }

    .tabs-wrap {
      .title-block {
        .title{
          font-size: 26px;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    .sidebar {
      min-width: 0;

      .section-head {
        .section-title {
          font-size: 16px;
        }
      }

      .my-points {
        .level {
          font-size: 24px;
        }
      }
    }

    .tabs-wrap {
      padding: 21px 5% 13px;
      background: transparent;

      .title-block {
        .title{
          font-size: 22px;
          margin-bottom: 16px;
        }
      }
    }
  }

}