@import "src/media";

.tabs {
    @media (max-width: $tablet) {
        position: relative;
    }

    &__toggle {
        display: none;
        width: 100%;
        cursor: pointer;
        padding: 5px 16px;
        height: 48px;
        border: 0;
        background: var(--emerald);
        border-radius: 12px;
        color: white;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.01em;
        word-break: break-all;

        @media (max-width: $tablet) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .icon {
            flex-shrink: 0;
            margin-left: 8px;

            path {
                fill: white;
            }
        }

        &--profile {
            justify-content: flex-start;
            height: 56px;
            padding: 5px 24px;
            background: rgba(83, 166, 131, 0.1);
            color: var(--black);
            font-weight: 400;

            .profile-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                border-radius: 50px;
                margin-right: 12px;
                background: var(--marine);
            }

            .text {
                margin-right: 8px;
            }

            .icon {
                margin: 0 0 0 auto;
                path {
                    fill: var(--emerald);
                }
            }
        }

        &--open {
            .icon {
                transform: rotate(180deg);
            }

            + .tabs__nav {
                display: flex;
                position: absolute;
                z-index: 100;
                width: 100%;
            }
        }
    }

    &__nav {
        display: flex;
        border-bottom: 1px solid rgba(83, 166, 131, 0.1);
        margin: 0 0 24px 0;

        @media (max-width: $tablet) {
            display: none;
            flex-direction: column;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
            border-radius: 0 0 8px 8px;
            border: 0;
        }

        .tabs-item {
            margin: 0 20px 0 0;

            @media (max-width: $tablet) {
                border-radius: 0;
                margin: 0;

                &:last-child {
                    .tabs-item__link {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }

            &__link {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 48px;
                padding: 8px 16px;
                border-radius: 6px 6px 0 0;
                font-weight: 300;
                font-size: 18px;
                text-align: center;
                line-height: 125%;
                letter-spacing: 0.01em;
                color: var(--emerald);
                background: transparent;
                outline: none;
                border: none;
                cursor: pointer;
                transition: background-color .25s, color .25s;

                @media (max-width: $tablet) {
                    width: 100%;
                    height: 100%;
                    min-height: 48px;
                    padding: 5px 16px;
                    justify-content: flex-start;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 160.5%;
                    letter-spacing: 0.01em;
                    color: var(--black);
                    background-color: white;
                }

                &:hover {
                    background-color: var(--active-marine);

                    @media (max-width: $tablet) {
                        background-color: var(--background);
                        color: var(--emerald);
                    }
                }

                &.active {
                    color: white;
                    font-weight: 500;
                    background: var(--emerald);

                    @media (max-width: $tablet) {
                        background: white;
                        color: var(--black);
                        font-weight: 400;

                        &:hover {
                            @media (max-width: $tablet) {
                                background-color: var(--background);
                                color: var(--emerald);
                            }
                        }
                    }
                }
            }
        }


        .item {
            padding: 0 16px;
            margin: 0 20px 0 0;

            &__button {
                font-weight: 300;
                font-size: 18px;
                line-height: 125%;
                letter-spacing: 0.01em;
                color: #034766;
                background: transparent;
                padding: 0;
                height: 48px;
                box-shadow: none;
                outline: none;
                border: none;
                cursor: pointer;
            }

            &.active {
                color: #fff;
                background: #034766;
                border-radius: 6px 6px 0 0;


                .item__button {
                    color: #fff;
                    font-weight: 500;
                }
            }

            &:last-child {
                margin: 0;
            }
        }
    }

    &__content {
        @media (max-width: $tablet) {
            margin-top: 20px;
        }

        .loading-wrap {
            position: absolute;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
            width: auto;
            height: auto;
        }

        .list-items {
            .animate {
                &--enter {
                    opacity: 0;
                    transform: translateX(30px) scale(0.98);
                }

                &--enter-active {
                    opacity: 1;
                    transform: translateX(0);
                    transition: opacity .5s, transform .5s;
                }

                &--exit {
                    opacity: 1;
                }

                &--exit-active {
                    opacity: 0;
                }
            }

            .item {
                padding: 16px;
                background: #fff;
                margin: 0 0 16px;
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
                border-radius: 8px;
                display: flex;

                @media (max-width: $tablet) {
                    flex-direction: column;
                    padding: 12px 12px 24px;
                }

                .image-prev {
                    margin: 0 20px 0 0;
                    display: flex;
                    position: relative;

                    @media (max-width: $tablet) {
                        margin: 0 0 12px;
                    }

                    img {
                        border-radius: 8px;
                        width: 130px;
                        height: 140px;
                        object-fit: cover;

                        @media (max-width: $tablet) {
                            width: 100%;
                            height: 150px;
                        }
                    }

                    &.video-image-prev {
                        background: rgba(0, 0, 0, 0.6);
                        z-index: 10;

                        &:before {
                            content: '';
                            background: rgba(0, 0, 0, 0.6);
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            border-radius: 8px;
                        }

                        .icon-play {
                            position: absolute;
                            left: calc(50% - 10px);
                            top: calc(50% - 14px);;
                            z-index: 15;
                        }

                    }
                }

                .document-prev {
                    width: 130px;
                    height: 140px;
                    min-width: 130px;
                    min-height: 140px;

                    background: rgba(255, 204, 102, 0.1);
                    border-radius: 8px;
                    margin: 0 20px 0 0;
                    position: relative;
                    z-index: 10;

                    .icon-document {
                        position: absolute;
                        left: calc(50% - 22px);
                        top: calc(50% - 22px);;
                        z-index: 15;
                    }
                }


                .item-info {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .head {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 0 12px;

                        .title {
                            margin-right: 12px;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 125%;
                            display: flex;
                            align-items: center;
                            color: #05060B;

                            @media (max-width: $tablet) {
                                font-weight: 500;
                                font-size: 16px;
                                letter-spacing: 0.01em;
                            }

                            a {
                                color: inherit;
                                word-break: break-all;
                            }
                        }

                        .view {
                            flex-shrink: 0;

                            @media (max-width: $tablet) {
                                display: none;
                            }

                            &.get-certificate,
                            a {

                                .button {
                                    font-size: 14px;
                                    line-height: 21px;
                                    height: 36px;
                                    padding: 0 24px;
                                }

                            }
                        }
                    }

                    .view--isMobile {
                        display: none;
                        justify-content: center;
                        margin-top: 20px;

                        @media (max-width: $tablet) {
                            display: flex;
                        }

                        .button {
                            height: 40px;
                            padding: 5px 24px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.03em;
                        }
                    }

                    .about-resource {
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.03em;
                        margin: 0 0 25px;
                        color: #05060B;
                    }

                    .about-course {
                        display: flex;
                        margin: 0 0 6px;

                        @media (max-width: $tablet) {
                            flex-direction: column;
                            margin: 0;
                        }

                        .icon {
                            margin: 0 7px 0 0;
                        }

                        .accreditation,
                        .time,
                        .lessons {
                            font-size: 16px;
                            line-height: 28px;
                            margin: 0 14px 0 0;
                            display: flex;
                            align-items: center;
                            letter-spacing: 0.01em;
                            color: #05060B;

                            @media (max-width: $tablet) {
                                margin: 0 0 14px 0;
                                font-size: 14px;
                                line-height: 160.5%;
                                letter-spacing: 0.01em;
                            }
                        }
                    }

                    .viewed {
                        .read {
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            display: flex;
                            align-items: center;
                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }
                    }

                    .video-info {
                        display: flex;


                        .view {
                            margin: 0 12px 0 0;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            display: flex;
                            align-items: center;
                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }

                        .tag {
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            /* identical to box height, or 15px */

                            display: flex;
                            align-items: center;

                            /* Grey */
                            margin: 0 12px 0 0;
                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }

                        .time {
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            /* identical to box height, or 15px */

                            display: flex;
                            align-items: center;

                            /* Grey */

                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }

                    }

                    .document-info {
                        .view {
                            margin: 0 12px 0 0;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            /* identical to box height, or 15px */

                            display: flex;
                            align-items: center;

                            /* Grey */

                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }
                    }

                    .interactive-presentation-info {
                        display: flex;

                        .interactive {
                            margin: 0 12px 0 0;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            /* identical to box height, or 15px */

                            display: flex;
                            align-items: center;

                            /* Grey */

                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }


                        .time {
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 125%;
                            /* identical to box height, or 15px */

                            display: flex;
                            align-items: center;

                            /* Grey */

                            color: #676A79;

                            .icon {
                                margin: 0 4px 0 0;
                            }
                        }
                    }

                    .starting {
                        border-top: 1px solid #E6F3F1;
                        display: flex;
                        padding: 8px 0 0;

                        .date {
                            min-width: 40px;
                            height: 44px;
                            background: #034766;
                            border-radius: 6px;
                            margin: 0 12px 0 0;
                            display: inline-flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            .day-of-month {
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 125%;
                                color: #fff;
                            }

                            .day-of-week {
                                font-weight: 300;
                                font-size: 12px;
                                line-height: 125%;
                                color: #fff;
                            }
                        }

                        .starting-date {
                            display: flex;
                            flex-direction: column;

                            .starting-title {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 125%;

                                color: #05060B;
                                margin: 0 0 4px 0px;
                            }

                            .day-and-year {
                                font-weight: 300;
                                font-size: 12px;
                                line-height: 125%;

                                color: #676A79;
                            }
                        }
                    }

                    .status {
                        border-top: 1px solid #E6F3F1;

                        padding: 8px 0 0;

                        .completed {
                            display: inline-flex;

                            font-weight: 400;
                            font-size: 14px;
                            line-height: 160.5%;
                            letter-spacing: 0.01em;

                            color: #1A9D86;
                            height: 30px;

                            padding: 4px 12px;

                            background: rgba(26, 157, 134, 0.1);
                            border-radius: 100px;

                            .icon {
                                margin: 0 0 0 10px;
                            }
                        }

                        .on-hold {
                            display: inline-flex;

                            font-weight: 400;
                            font-size: 14px;
                            line-height: 160.5%;
                            letter-spacing: 0.01em;

                            color: #19A3DE;
                            height: 30px;
                            padding: 4px 12px;
                            background: rgba(25, 163, 222, 0.1);
                            border-radius: 100px;

                            .icon {
                                margin: 0 0 0 10px;
                            }
                        }
                    }

                    .schedule {
                        display: flex;
                        align-items: center;

                        .chart {
                            position: relative;
                            height: 8px;
                            width: 100%;
                            margin: 0 10px 0 0;
                            background: rgba(230, 243, 241, 0.3);
                            border-radius: 12px;

                            .success-chart {
                                height: 8px;
                                position: absolute;
                                background: linear-gradient(105.3deg, #2380AA 0%, #0B978F 100%);
                                border-radius: 12px;
                            }
                        }

                        .success {
                            font-size: 14px;
                            line-height: 160.5%;
                            /* or 22px */

                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: 0.01em;

                            /* Mecaso/Emerald */

                            color: #034766;
                        }
                    }

                }

            }
        }
    }

    @media (min-width: 1101px) and (max-width: 1366px) {
        &__content {
            .list-items {
                .item {
                    .item-info {
                        .head {
                            flex-wrap: wrap;

                            .title {
                                margin-right: 0;
                            }

                            .view {
                                margin: 20px 0 20px auto;
                            }
                        }
                    }
                }
            }
        }
    }
}