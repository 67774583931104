.c-input {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.01em;
    color: var(--black);
    cursor: pointer;

    &__input {
        display: none;

        &:checked {
            ~ .c-input__box--checkbox {
                border-color: var(--orange);
                background-color: var(--orange);

                .c-input__icon {
                    opacity: 1;
                }
            }

            ~ .c-input__box--radio {
                border-color: var(--orange);

                &::before {
                    opacity: 1;
                }
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        border: 2px solid var(--grey);
        border-radius: 4px;
        transition: all .25s;

        &--radio {
            border-radius: 40px;

            &::before {
                content: "";
                opacity: 0;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 20px;
                background-color: var(--orange);
                transition: all .25s;
            }
        }
    }

    &__icon {
        flex-shrink: 0;
        opacity: 0;
        transition: all .25s;
    }
}