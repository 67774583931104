.delete-account-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 125%;
    /* identical to box height, or 40px */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.015em;

    /* Mecaso / Black */

    margin: 0 0 24px;
    color: #05060B;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    margin: 0 0 90px;

    color: #5A5A5A;
  }

  .delete-actions-wrap {
    display: flex;

    .button {
      width: 234px;
      height: 54px;
      margin: 0 16px 0 0;

      &:last-child {
        margin: 0;
      }

    }
  }
}