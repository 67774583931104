@import "src/media";

.page-account-settings {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 55px 126px 50px 24px;
  width: 100%;
  background: var(--background);

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.015em;
    margin: 0 0 40px;
    color: #05060B;
    display: flex;
    align-items: center;

    .back-home {
      display: flex;
      align-items: center;
    }

    .icon {
      margin: 0 12px 0 0;
    }
  }

  .title-block {
    font-weight: 600;
    font-size: 20px;
    line-height: 125%;
    /* or 25px */

    display: flex;
    align-items: center;
    letter-spacing: 0.015em;

    margin: 0 0 32px;

    color: #034766;

  }

  .account-settings-form {
    .settings {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .basics {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 16px 18px;
        width: calc(50% - 12px);
      }

      .professionals {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 16px 18px;
        width: calc(50% - 12px);
      }


      .basics-fields {
        width: 100%;

        .row {
          margin: 0 0 16px;
          position: relative;

          .form-label {
            position: absolute;
            left: 18px;
            top: 18px;
            z-index: 10;
            pointer-events: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 125%;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: var(--grey);
            transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out;
          }

          .text-input {
            border: 1px solid var(--grey);
            border-radius: 6px;
            width: 100%;
            height: 56px;
            padding: 0 20px 0;
            color: var(--black);
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
          }


          .form-select-label {
            padding: 0 10px;
            background: #fff;
          }

          input:focus,
          textarea:focus {
            outline: none;
            background-size: 100% 100%, 100% 100%;
            transition-duration: 0.3s;
            box-shadow: none;
            border: 1px solid var(--emerald);
          }


          input:focus + label,
          input:not(:placeholder-shown) + label,
          textarea:focus + label,
          textarea:not(:placeholder-shown) + label {
            top: -10px;
            background: var(--white);
            padding: 0 2px;
            font-weight: 300;
            font-size: 12px;
            line-height: 147.5%;
            display: flex;
            align-items: center;
            letter-spacing: 0.015em;
            color: var(--emerald);
          }

          ::-webkit-input-placeholder {
            opacity: 0;
            color: var(--grey);
          }

          input:focus::-webkit-input-placeholder,
          textarea:focus::-webkit-input-placeholder {
            opacity: 1;
          }

          textarea:not(:focus):not(:placeholder-shown) + label,
          input:not(:focus):not(:placeholder-shown) + label {
            color: var(--black);
          }

          .error {
            font-size: 12px;
            color: var(--red);
          }

          &.input-error {

            .text-area,
            .text-input {
              border: 1px solid var(--red);
            }

            textarea:not(:placeholder-shown) + label,
            input:not(:placeholder-shown) + label {
              color: var(--red);
            }

          }

          &:last-child {
            margin: 0;
          }

        }


        .submit-wrap {
          margin: 45px 0 0;
          display: flex;
          justify-content: center;

          .button {
            width: 234px;
          }
        }
      }
    }

    .language-block {
      padding: 16px 18px;
      width: calc(50% - 12px);
      margin: 0 0 18px;
    }

    .my-learning-area {
      background: #FFFFFF;
      border-radius: 12px;
      padding: 16px 18px;
      width: 100%;

      .list-area {
        display: flex;
        flex-wrap: wrap;

        .item {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          /* identical to box height */
          border: 0.5px solid #C9C9C9;
          box-sizing: border-box;
          border-radius: 100px;
          display: flex;
          align-items: center;
          letter-spacing: 0.03em;
          padding: 12px 16px;
          /* Mecaso / Black */
          color: #05060B;


          /* Inside Auto Layout */

          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0 10px 12px;

          &.add-more {
            color: #fff;
            background: #1A9D86;
            border: #1A9D86;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 38px 0 0;

      .actions-buttons {
        display: flex;


        .cancel {
          border: 1px solid #034766;
          box-sizing: border-box;
          border-radius: 100px;

          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          letter-spacing: 0.01em;
          color: #034766;
          margin: 0 16px 0 0;
          width: 220px;
          height: 56px;
          -webkit-transition: linear background 250ms;
          -moz-transition: linear background 250ms;
          -o-transition: linear background 250ms;
          transition: linear background 250ms;

          &:hover {
            background: rgba(230, 243, 241, 0.3);
            color: rgba(3, 71, 102, 0.8);
          }
        }

        .save {
          cursor: pointer;
          width: 220px;
          background: #1A89A0;
        }
      }

      .delete-account {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: #19A3DE;

        cursor: pointer;
      }

    }

    .form-selected-input {
      width: 100%;

      .MuiSelect-icon {
        -webkit-transition: linear all 250ms;
        -moz-transition: linear all 250ms;
        -o-transition: linear all 250ms;
        transition: linear all 250ms;
        right: 20px;
      }
    }
  }

  @media (max-width: $desktop) {
    padding: 0 24px 24px;

    .account-settings-form {
      .actions {
        flex-direction: column;

        .actions-buttons {
          margin: 0 0 30px;
        }
      }
    }
  }

  @media (max-width: $tablet) {
    padding: 0 5% 43px;

    .title-block {
      font-size: 16px;
    }

    .account-settings-form {
      .settings {
        flex-direction: column;

        .basics {
          width: 100%;
          margin-bottom: 16px;
        }

        .professionals {
          width: 100%;
        }
      }

      .actions {
        margin-top: 40px;

        .actions-buttons {
          flex-direction: column;

          .button {
            margin: 0;

            + .button {
              margin-top: 20px;
            }
          }
        }
      }
    }


  }
}