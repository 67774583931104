.popup {
  position: fixed;
  bottom: 23px;
  right: 45px;
  background: var(--white);
  border: 1px solid var(--blood);
  border-left: 8px solid rgba(231, 76, 60, 0.4);
  padding: 16px 19px 16px 52px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.14), 0 1px 18px rgba(0, 0, 0, 0.12), 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  max-width: 356px;
  //this insane z-index we need because there is a jivochat widget with awfully big z-index
  z-index: 9999999999;

  &.success {
    border: 1px solid var(--mint);
    border-left: 8px solid rgba(26, 157, 134, 0.4);
  }

  &__close {
    position: absolute;
    top: 11px;
    right: 19px;
    width: 24px;
    height: auto;
    flex-shrink: 0;
    cursor: pointer;
  }

  &__warning {
    position: absolute;
    left: 16px;
    top: 16px;
    flex-shrink: 0;
  }

  &__success {
    position: absolute;
    left: 16px;
    top: 16px;
    flex-shrink: 0;
  }

  &__heading {
    margin: 0 24px 4px 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    color: var(--black);
    opacity: 0.56;
  }

  &__title {
    margin: 0 24px 8px 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
    color: var(--depressed);
  }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: var(--black);
    opacity: 0.56;
  }

  &--enter {
    opacity: 0;
    transform: translateY(20px) scale(0.9);
  }

  &--enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity .5s, transform .3s;
  }

  &--exit {
    opacity: 1;
  }

  &--exit-active {
    opacity: 0;
    transform: translateY(20px) scale(0.7);
    transition: opacity .5s, transform .5s;
  }
}
