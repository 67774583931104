.progress-bar {
    position: relative;
    width: 100%;
    height: 8px;
    background: var(--active-marine);
    border-radius: 12px;

    &__fill {
        position: absolute;
        height: 100%;
        border-radius: 8px;
        background: linear-gradient(105.3deg, #2380AA 0%, #0B978F 100%);
    }
}