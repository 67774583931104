@import "src/media";

.points-type {
    display: grid;
    grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    grid-column-gap: 12px;
    padding: 16px 16px 0;
    margin-bottom: 17px;

    @media (max-width: $desktop-l) {
        grid-template-columns: 100%;
        grid-row-gap: 12px;
    }

    &__card {
        padding: 12px;
        border-radius: 10px;
        background: var(--active-marine);
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        word-break: break-word;

        @media (max-width: $desktop-l) {
            padding: 16px;
            text-align: center;
        }

        &--pharma {
            background: rgba(25, 163, 222, 0.1);

            .points-type__num {
                color: var(--blue);
            }
        }
    }

    &__num {
        display: block;
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--mint);
    }
}