.empty-tab {
  margin: 50px 0;

  .image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 16px;
  }

  .empty-text {
    font-size: 16px;
    line-height: 28px;
    margin: 0 0 24px;
    text-align: center;
    letter-spacing: 0.01em;

    /* Grey/dark */

    color: #5A5A5A;

  }

  .find-course {
    display: flex;
    justify-content: center;

    a {

      background: #1A89A0;
      border-radius: 100px;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      display: inline-flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
    }
  }
}