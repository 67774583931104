@import "src/media";

.page-my-certifications {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 42px 32px 0 42px;
  width: 100%;
  background: var(--background);

  .headline {
    display: flex;
    align-items: center;
    margin: 0 0 23px;

    .title-block {

      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--black);
        margin: 0 56px 0 0;
      }
    }

    .search-certificate {
      width: 583px;
      height: 56px;
      border: 1px solid var(--grey);
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;

      .icon {

        margin: 0 16px;
      }

      input {
        background: transparent;
        border: 0;
        width: 100%;
      }
    }
  }

  .header-list-certificates {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: 0.015em;
    color: var(--emerald);

    display: flex;
    align-items: center;
    background: var(--white);
    padding: 0 16px;
    width: 100%;
    height: 48px;
    margin: 0 0 16px;
    border-radius: 8px;

    .course {
      width: 44%;
    }

    .awared-on {
      width: 11%;
      //cursor: pointer;
      display: flex;
      align-items: center;

      .icon-awared {

        margin: 0 0 0 10px;

        .icon {

          &.active {
            svg {
              path {
                stroke: #19A3DE
              }
            }
          }

          svg {
            path {
              stroke: #C9C9C9
            }
          }
        }
      }
    }

    .code {
      width: 11%;
    }

    .duration {
      width: 34%;
    }
  }

  .list-certificates {

    .list-certificates-scroll {

      height: calc(100vh - 300px);
      overflow-y: hidden;

      .item {
        display: flex;
        align-items: center;
        background: var(--white);
        padding: 16px 16px;
        box-shadow: 0 2px 19px rgba(50, 50, 71, 0.05);
        border-radius: 12px;

        margin: 0 0 16px;

        .course {
          width: 44%;
          display: flex;

          .image-certificate {

            display: flex;

            .image {
              width: 120px;
              height: 130px;
              object-fit: cover;
              border-radius: 8px;
            }

          }

          .course-certificate {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 0 0 18px;

            .certificate-title {
              font-weight: 600;
              font-size: 20px;
              line-height: 125%;
              display: flex;
              align-items: center;
              letter-spacing: 0.015em;
              color: var(--black);
              margin: 0 0 20px;
            }

            .view {
              font-weight: 400;
              font-size: 14px;
              line-height: 160.5%;
              display: flex;
              align-items: center;
              text-align: center;
              letter-spacing: 0.01em;
              color: var(--blue);
              cursor: pointer;

              -webkit-transition: linear transform 250ms;
              -moz-transition: linear transform 250ms;
              -o-transition: linear transform 250ms;
              transition: linear transform 250ms;

              &:hover {

              }
            }
          }
        }

        .awared-on {
          width: 11%;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: var(--emerald);
        }

        .code {
          width: 11%;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: var(--emerald);
        }

        .duration {
          width: 34%;
          display: flex;
          justify-content: space-between;

          .duration-time {
            font-size: 16px;
            font-weight: 500;
            line-height: 125%;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: var(--emerald);
          }

        }

      }
    }


  }


  @media (max-width: $tablet-l) {
    padding: 42px 15px 0 15px;

    .list-certificates {

      .list-certificates-scroll {
        .item {

          .course {
            width: 39%;

            .course-certificate {
              padding: 0 0 0 6px;

              .certificate-title {
                font-size: 16px;
                margin: 0;
              }
            }

            .image-certificate {
              .image {
                width: 100px;
                height: 100px;
              }
            }
          }


          .awared-on {
            font-size: 14px;
            width: 13%;
          }

          .code {
            width: 13%;
            font-size: 14px;
          }

          .duration {
            width: 35%;

            .duration-time {
              font-size: 14px;
            }
          }
        }
      }
    }

    .header-list-certificates {
      .course {
        width: 39%;
      }

      .awared-on {
        width: 13%;
      }

      .code {
        width: 13%;
      }

      .duration {
        width: 35%;
      }
    }
  }

  @media (max-width: $tablet) {
    padding: 16px 5%;

    .headline {
      .title-block {
        .title {;
          margin: 0;
          font-size: 22px;
          letter-spacing: 0.03em;
        }
      }
    }

    .header-list-certificates {
      display: none;
    }

    .list-certificates {

      .list-certificates-scroll {
        height: calc(100vh - 150px);

        .item {
          flex-direction: column;

          .course {
            width: 100%;
            flex-direction: column;

            .image-certificate {
              .image {
                width: 100%;
                height: 170px;
                margin: 0 0 15px;
              }
            }

            .course-certificate {
              padding: 0;

               .certificate-title {
                margin: 0 0 15px;
              }
            }

          }

          .awared-on {
            width: 100%;
            margin: 0 0 15px;
          }

          .code {
            width: 100%;
            margin: 0 0 15px;
          }

          .duration {
            width: 100%;
            flex-direction: column;

            .duration-time {
              margin: 0 0 15px;
            }
          }

          .mobile-title {
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
}