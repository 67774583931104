.register-form {
    display: flex;
    flex-direction: column;

    &__input {
        width: 100%;
        height: 56px;
        margin-bottom: 16px;
        padding: 14px 18px;
        border: 1px solid #C9C9C9;
        border-radius: 6px;
        font-size: 16px;
        line-height: 28px;
        transition: all .25s;

        &::placeholder {
            color: var(--grey);
        }

        &:focus, &:hover {
            border-color: var(--marine);
        }
    }

    &__error {
        position: relative;
        bottom: 8px;
        margin-left: 16px;
        color: var(--red);
        font-size: 14px;
        line-height: 16px;
    }

    &__select {
        margin-bottom: 16px;
    }

    &__submit {
        width: 259px;
        margin: 16px auto 0;
        max-width: 100%;
    }
}