@import "src/media";

.check-label {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 4px 12px;
    background: rgba(26, 157, 134, 0.1);
    border-radius: 100px;
    font-size: 14px;
    line-height: 160.5%;
    letter-spacing: 0.01em;
    color: var(--mint);

    @media (max-width: $tablet) {
        font-size: 12px;
    }

    &__icon {
        flex-shrink: 0;
        margin-left: 11px;

        @media (max-width: $tablet) {
            margin-left: 8px;
        }
    }
}