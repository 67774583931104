.modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  -o-transition: all 250ms linear;
  transition: all 250ms linear;
  z-index: 30;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .modal-content {
    background: #fff;
    border-radius: 12px;
    padding: 50px 48px;
    transform: scale(0.6);
    width: 670px;
    max-width: 100%;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;
    position: relative;

    &.active {
      transform: scale(1);
    }

    .close-modal {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
  }
}