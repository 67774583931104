.image-placeholder {
    background-color: #eeeded;
    animation: 2s linear infinite placeholderShimmer;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transform: scale(0.98);
        transition: opacity .5s, transform .3s;


        &--loaded {
            opacity: 1;
            transform: scale(1);
        }
    }
}

@keyframes placeholderShimmer {
    10% {
        background-color: #eeeded;
    }
    50% {
        background-color: #f6f6f6;
    }
    90% {
        background-color: #eeeded;
    }
}