.progress {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;

    &__text {
        flex-shrink: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: var(--grey);
    }

    .progress-bar {
        margin: 0 10px;
    }
}