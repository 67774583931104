@import "src/media";

.details-course {
  display: flex;
  justify-content: space-between;
  margin-right: -40px;
  height: 100%;
  transition: all 0.5s linear;

  @media (max-width: $tablet-l) {
    overflow: hidden;
  }

  .details {
    width: auto;
    flex-grow: 1;
    background: #F9F9F9;
    padding: 50px 32px 80px;

    @media (max-width: $desktop) {
      padding: 50px 24px 80px 32px;
    }

    @media (max-width: $tablet) {
      padding: 32px 5% 48px;
      margin: 0;
    }

    &__view {
      height: 40px;
      margin: 0 0 20px auto;
      padding: 16px 23px 16px 24px;
      border: 1px solid var(--emerald);
      border-radius: 50px;
      background: transparent;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.03em;
      color: var(--emerald);
    }

    .headline-course {
      display: flex;
      justify-content: space-between;
      margin: 0 0 14px;

      @media (max-width: $tablet) {
        margin: 0 0 20px;
      }

      .back-to-overview {
        display: flex;
      }

      .back {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #034766;

        .icon {
          flex-shrink: 0;
          margin: 0 12px 0 0;
        }
      }

      .add-courses {
        flex-shrink: 0;

        .button {
          padding: 5px 24px;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 125%;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #05060B;

      @media (max-width: $tablet) {
        font-size: 22px;
      }
    }

    .body-course {
      max-width: 720px;
      margin: 0 auto;

      .my-progress {
        background: #FFFFFF;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        padding: 20px 16px;

        margin: 0 0 24px;

        .title-block {
          margin: 0 0 12px;
        }

        .schedule {
          display: flex;
          align-items: center;

          .chart {
            position: relative;
            height: 8px;
            width: 100%;
            margin: 0 10px 0 0;
            background: rgba(230, 243, 241, 0.3);
            border-radius: 12px;

            .success-chart {
              height: 8px;
              position: absolute;
              background: linear-gradient(105.3deg, #2380AA 0%, #0B978F 100%);
              border-radius: 12px;
            }
          }

          .success {
            font-size: 14px;
            line-height: 160.5%;
            /* or 22px */

            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.01em;

            /* Mecaso/Emerald */

            color: #034766;
          }
        }
      }

      .preview {
        position: relative;
        margin: 24px 0;

        @media (max-width: $tablet) {
          margin: 16px 0 20px;
        }

        .image-placeholder {
          height: 360px;
          width: 100%;
          border-radius: 8px;

          img {
            height: 100%;
            width: 100%;
            border-right: 0;
          }

          .fullscreen-icon {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
          }
        }

        img {
          height: 320px;
          width: 100%;
          border-radius: 8px;
          object-fit: cover;
          @media (min-width: 1600px) {
            height: 360px;
          }
          @media (min-width: 1900px) {
            height: 400px;
          }
        }

        .title {
          position: absolute;
          left: 32px;
          top: 32px;
          font-weight: 600;
          font-size: 24px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: #fff;

        }
      }

      .content {
        h2 {
          font-weight: 600;
          font-size: 20px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.015em;
          color: #05060B;
          margin: 0 0 20px 0;

          @media (max-width: $tablet) {
            margin: 0 0 4px 0;
          }
        }


        p, .text {
          font-size: 14px;
          line-height: 160.5%;
          letter-spacing: 0.01em;
          margin: 0 0 15px 0;
          color: #05060B;
        }
      }

      .skills {
        background: #fff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.02);
        border-radius: 6px;
        padding: 20px 16px;
        margin: 0 0 36px;

        @media (max-width: $tablet) {
          margin-top: 24px;
        }

        .title-block {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          margin: 0 0 16px;

          display: flex;
          align-items: center;
          letter-spacing: 0.01em;

          /* Mecaso / Black */

          color: #05060B;
        }

        .skills-list {
          display: flex;
          flex-wrap: wrap;

          .item {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            letter-spacing: 0.03em;
            color: #05060B;
            padding: 12px 16px;
            border: 0.5px solid #C9C9C9;
            border-radius: 100px;
            margin: 0 12px 12px 0;
            -webkit-transition: all 250ms linear;
            -moz-transition: all 250ms linear;
            -o-transition: all 250ms linear;
            transition: all 250ms linear;

            &.show-all-skills {
              background: #1A9D86;
              border-radius: 100px;
              font-weight: 400;
              color: #fff;
              border: 0.5px solid #1A9D86;
              cursor: pointer;


              &:hover {
                border: 0.5px solid rgba(26, 157, 134, 0.8);
                background: rgba(26, 157, 134, 0.8);
                box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
              }

            }


            &:hover {
              background: rgba(83, 166, 131, 0.1);
            }
          }
        }
      }

      .actions-course {
        display: flex;
        margin-top: 28px;

        @media (max-width: $tablet) {
          flex-direction: column;
          align-items: center;
        }

        .button {
          height: 56px;
          padding: 14px 26px;

          @media (max-width: $tablet) {
            margin: 0 0 16px 0;
          }

          @media (max-width: 480px) {
            width: 100%;
          }

          &.start {
            padding: 14px 94px;
          }

          &.keep {
            margin: 0 16px 0 0;

            @media (max-width: $tablet) {
              margin: 0 0 16px 0;
            }
          }

          &.mark-completed {
            margin: 0 16px 0 0;

            @media (max-width: $tablet) {
              margin: 0 0 16px 0;
            }
          }
        }
      }
    }
  }

  .disabled-link {
    cursor: auto;
  }

  .sidebar {
    padding: 16px 38px 24px 24px;
    min-width: 384px;
    width: 26%;
    flex-shrink: 0;
    background-color: white;

    @media (max-width: $desktop) {
      padding: 16px 38px 24px 0;
    }

    @media (max-width: $tablet-l) {
      max-width: 720px;
      margin: 0 auto;
      padding: 0;
      background: var(--background);
    }

    @media (max-width: $tablet) {
      padding: 0 5%;
      max-width: inherit;
      min-width: 0;
      margin: 0;
    }

    &.topics {
      padding: 16px 38px 9px 24px;
      height: calc(100vh - 100px - 16px);

      @media (max-width: $desktop) {
        padding: 16px 38px 9px 0;
      }

      @media (max-width: $tablet-l) {
        position: absolute;
        top: 78px;
        max-width: inherit;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 32px 5% 20px;
        background: var(--background);

        .animate {
          &--enter {
            opacity: 0;
            transform: translateX(800px);
          }

          &--enter-active {
            opacity: 1;
            transform: translateX(0);
            transition: opacity .3s, transform .3s;
          }

          &--exit {
            opacity: 1;
          }

          &--exit-active {
            opacity: 0;
          }
        }

        .post-list-data {
          .item {
            margin: 0 0 15px 0;
          }
        }
      }

      @media (max-width: $tablet) {
        top: 59px;
      }

      .ps__rail-y, .ps__thumb-y {
        width: 4px;
        right: 16px;

        &:hover > .ps__thumb-y {
          width: 4px;
        }
      }
    }

    .sidebar-container {
      padding: 24px 0;
      margin: 0 0 24px 0;
      background: #fff;
      box-shadow: 0 2px 19px rgba(50, 50, 71, 0.05);
      border-radius: 6px;

      .about {
        position: relative;
        border-bottom: 0.5px solid #E6F3F1;


        .title-block {
          font-weight: 600;
          font-size: 20px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: #05060B;
          margin: 0 0 28px;
          padding: 0 16px;
        }

        .about-list-items {
          padding: 0 14px;

          .points-type {
            padding: 0;
          }

          .item {
            display: flex;
            margin: 0 0 12px;

            .icon {
              width: 48px;
              display: flex;
              justify-content: center;
              margin: 0 8px 0 0;
            }

            .text-wrap {
              display: flex;
              flex-direction: column;

              .title {
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: #05060B;
                margin: 0 0 4px 0;
              }

              .content {
                font-weight: 500;
                font-size: 16px;
                line-height: 125%;
                letter-spacing: 0.01em;
                color: #05060B;
              }
            }
          }
        }
      }

      .author-course {
        display: flex;
        margin: 22px 0 0;
        border-bottom: 0.5px solid #E6F3F1;

        padding: 0 0 22px;

        .ava {
          margin: 0 10px 0 0;
          padding: 0 0 0 14px;
          display: flex;


          img {
            border-radius: 50%;
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }

        .author {
          display: flex;
          flex-direction: column;

          .author-header {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;

            color: #05060B;
            margin: 0 0 4px 0px;
          }

          .name {
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            letter-spacing: 0.01em;
            color: #05060B;
          }
        }
      }

      .materials {
        padding: 24px 0 0;

        .title-block {
          font-weight: 600;
          font-size: 20px;
          line-height: 125%;
          letter-spacing: 0.015em;
          color: #05060B;
          margin: 0 0 30px;
          padding: 0 16px;
        }

        .list-items {
          padding: 0 12px;


          .presentations {

          }

          .item {
            display: flex;

            .icon {
              margin: 0 12px 0 0;
            }

            &.files {
              display: inline-flex;
              align-items: center;
            }

            .item-details {
              .head {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.03em;
                margin: 0 0 2px 0;
                color: #05060B;
              }

              .get-info {
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: #05060B;
              }
            }
          }


        }
      }
    }


    .additional-information {


      .additional-information-list {
        border-top: 0.5px solid #E6F3F1;

        .item {
          padding: 12px;
          background: rgba(230, 243, 241, 0.3);
          border-radius: 12px;
          margin: 24px 0;
          display: flex;

          @media (max-width: $tablet-l) {
            background-color: white;
            margin: 12px 0;

            &:first-child {
              margin-top: 24px;
            }
            &:last-child {
              margin-bottom: 39px;
            }
          }

          .icon {
            display: flex;
            margin: 0 12px 0 0;
          }

          .text-wrap {

            .title {
              font-weight: 500;
              font-size: 16px;
              line-height: 125%;
              letter-spacing: 0.01em;
              color: #05060B;
              margin: 0 0 4px 0;
            }

            .content {
              font-size: 14px;
              line-height: 160.5%;
              letter-spacing: 0.01em;

              color: #05060B;
            }
          }
        }
      }
    }

    .post-list-data {
      & + .post-list-data {
        margin-top: 32px;
      }

      .go-back {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-bottom: 24px;
        font-weight: 600;
        font-size: 24px;
        line-height: 125%;
        letter-spacing: 0.015em;
        cursor: pointer;
        color: var(--black);

        .icon {
          flex-shrink: 0;
          margin-right: 20px;
        }
      }

      .head-post {
        background: rgba(230, 243, 241, 0.3);
        border-radius: 12px;
        border-right: 4px solid #19A3DE;
        display: flex;
        margin: 0 0 15px;
        padding: 18px 16px;
        cursor: pointer;

        &.active {
          background: var(--marine);

          .head-post-title {
            color: white;
          }

          .icon {
            background: rgba(230, 243, 241, 0.3);
          }
        }

        .icon {
          width: 48px;
          height: 48px;
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          margin: 0 16px 0 0;
          background: #1A89A0;
          border-radius: 50%;
        }

        .head-post-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: #034766;
        }
      }

      .item {
        background-color: white;
        border-radius: 8px;
        padding: 12px 14px;
        display: flex;
        margin: 0 0 15px 11px;
        cursor: pointer;
        transition: background-color .1s linear;

        &.active {
          background: rgba(230, 243, 241, 1);

          .icon {
            background: var(--marine);

            .complete-icon {
              opacity: 1;
            }
          }
        }

        &.lock {
          cursor: auto;

          .icon {
            background: rgba(230, 243, 241, 0.3);
          }

          .info-title {
            color: var(--grey);
          }

          .info-short-description {
            color: var(--dirty);
          }
        }

        .icon {
          position: relative;
          width: 48px;
          height: 48px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          justify-content: center;
          margin: 0 16px 0 0;
          background: rgba(83, 166, 131, 0.1);
          border-radius: 50%;
          transition: background-color .1s linear;

          .complete-icon {
            position: absolute;
            right: -8px;
            bottom: -2px;
            opacity: 0.1;
            transition: opacity .1s linear;
          }
        }

        .item-info {
          max-width: 230px;
        }

        .info-title {
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          letter-spacing: 0.01em;
          margin: 0 0 10px;
          color: #05060B;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .about {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .info-short-description {
          font-weight: 300;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.03em;
          margin: 0 12px 0 0;
          color: #5A5A5A;
        }

        .time {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .time-icon {
            margin: 0 4px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .get-time {
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #5A5A5A;
          }
        }
      }
    }
  }

  @media (max-width: $desktop) {
    margin-right: 0;
  }

  @media (max-width: $tablet-l) {
    flex-direction: column;

    .sidebar {
      width: 100%;

      .post-list-data {
        .item {
          .item-info {
            max-width: calc(100% - (48px + 16px));
            width: 100%;
          }
        }
      }
    }
  }
}