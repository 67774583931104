@import "src/media";

.login-wrap {
    text-align: center;
    width: 100%;
    background: var(--grey_light);
    padding: 0;

    .image-wrap {
        @media (max-width: $tablet) {
            display: none;
        }
    }

    .text-title {
        h1 {
            font-weight: 600;
            font-size: 32px;
            line-height: 125%;
            letter-spacing: 0.015em;
            margin: 0 0 16px;
            color: #05060B;

            @media (max-width: $tablet) {
                font-size: 24px;
            }
        }
    }

    .text-description {
        p {
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.01em;
            margin: 0 0 32px;
            color: var(--grey_dark);
        }
    }

    .admin-login {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        margin: 16px 0 0;
        color: var(--grey_dark);

        .link {
            text-transform: uppercase;
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .btn-wrap {
        display: flex;
        flex-direction: column;
        margin: 0 0 44px;

        .item {
            background: #fff;
            box-shadow: 0 4px 9px rgba(0, 0, 0, 0.02);
            border-radius: 12px;
            margin: 0 0 16px;
            width: 495px;
            height: 84px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            cursor: pointer;

            @media (max-width: $tablet) {
                width: 100%;
                height: 64px;
            }

            &.doc-check {
                .logo-icon {
                    @media (max-width: $tablet) {
                        display: flex;
                        justify-content: flex-start;

                        svg {
                            width: 60%;
                            height: auto;
                        }
                    }
                }

                .arrow-icon {
                    height: 84px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}