.doc-check {
    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        margin: 0 0 24px;
        color: var(--black);
    }

    &__iframe {
        display: flex;
        justify-content: center;
    }
}