.presentation {
    position: absolute;
    width: 100%;
    padding: 27px 80px 80px;
    background-color: var(--background);

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 51px;

        .icon {
            flex-shrink: 0;
            cursor: pointer;
        }
    }

    &__back {
        display: inline-flex;
        align-items: center;
        color: var(--black);

        .icon {
            flex-shrink: 0;
            margin: 0 12px 0 0;
        }
    }

    &__title {
        margin-right: 16px;
        font-weight: 600;
        font-size: 24px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
    }

    &__content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 288px);
        background-color: white;
    }
}