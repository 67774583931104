@import "src/media";

.page-privacy {
  height: 100%;
  padding: 55px 126px 50px 24px;
  width: 100%;
  background: var(--background);

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 125%;
    letter-spacing: 0.015em;
    margin: 0 0 40px;
    color: #05060B;
    display: flex;
    align-items: center;

    .back-home {
      display: flex;
      align-items: center;
    }

    .icon {
      margin: 0 12px 0 0;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 160.5%;
    /* or 22px */
    margin: 0 0 20px;
    letter-spacing: 0.01em;
  }

  @media (max-width: $desktop) {
    padding: 24px 24px 50px 24px;
  }
}