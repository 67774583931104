@import "src/media";

.rating {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    @media (max-width: $tablet) {
        display: grid;
        grid-template-columns: repeat(5, auto);
        grid-column-gap: 2%;
        grid-row-gap: 16px;
        width: 100%;
        min-width: 240px;
        max-width: 430px;
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 64px;
        height: 64px;
        background: rgba(230, 243, 241, 0.3);
        border: 1px solid #E6F3F1;
        border-radius: 100px;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        letter-spacing: 0.015em;
        cursor: pointer;

        & + & {
            margin-left: 8px;

            @media (max-width: $tablet) {
                margin: 0;
            }
        }

        &.active {
            background-color: var(--marine);
            color: white;
        }
    }


    @media (max-width: $tablet-l) {
        &__circle {
            width: 50px;
            height: 50px;
            font-size: 18px;
        }
    }

    @media (max-width: 400px) {
        &__circle {
            width: 40px;
            height: 40px;
        }
    }
}