.upcoming {
  background: #fff;
  box-shadow: 0 2px 19px rgba(50, 50, 71, 0.05);
  border-radius: 12px;


  .upcoming-items {
    padding: 10px 0;

    .item {
      display: flex;
      margin: 12px 0;
      padding: 0 12px;

      .date {
        min-width: 56px;
        height: 64px;
        background: #034766;
        border-radius: 6px;
        margin: 0 12px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .number {
          font-weight: 500;
          font-size: 20px;
          line-height: 125%;
          color: #fff;
        }

        .day-of-week {
          font-weight: 300;
          font-size: 12px;
          line-height: 125%;
          color: #fff;
        }
      }

      .info {
        .title {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: #05060B;
          margin: 0 0 4px 0px;
        }

        .full-date {
          display: flex;
          align-items: center;

          .icon {
            display: flex;
            align-items: center;
          }

          .calendar-date {
            font-size: 14px;
            line-height: 160.5%;

            letter-spacing: 0.01em;
            color: #5A5A5A;
            margin: 0 0 0 4px;
          }
        }
      }

    }
  }

}