.loading-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .loading {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }

  .loading div {
    position: absolute;
    background: var(--emerald);
    opacity: 1;
    border-radius: 50%;
    animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

  .loading div:nth-child(2) {
    animation-delay: -.7s;
  }
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}