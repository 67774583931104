
.no-items {
  padding: 0 25px;

  .icon {
    display: flex;
    justify-content: center;
    margin: 34px 0;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 160.5%;
    text-align: center;
    letter-spacing: 0.01em;

    color: #5A5A5A;
  }

  .add-courses-wrap {
    display: flex;
    justify-content: center;
    padding: 0 0 70px;

    .add-courses {
      margin: 28px 0 0;
      padding: 8px 24px;
    }
  }

}