@import "src/media";

.page-all-courses {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 42px 12px 0 32px;
  width: 100%;
  background: var(--background);

  @media (max-width: $tablet) {
    padding: 16px 5%;
    background-color: white;
  }

  .headline {
    display: flex;
    align-items: center;
    margin: 0 0 23px;
    position: relative;

    .title-block {

      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        color: var(--black);
        margin: 0 56px 0 0;

        @media (max-width: $tablet) {
          font-size: 22px;
          letter-spacing: 0.03em;
        }
      }
    }

    .get-category {
      height: 56px;
      border: 1px solid #C9C9C9;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 15px;
      margin: 0 28px 0 0;

      .icon {
        margin: 0 16px 0 0;
      }

      .text {
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        margin: 0 50px 0 0;

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* Mecaso / Black */

        color: #05060B;
      }

      .icon-arrow {
        -webkit-transition: linear transform 250ms;
        -moz-transition: linear transform 250ms;
        -o-transition: linear transform 250ms;
        transition: linear transform 250ms;

        &.rotate {

          transform: rotate(-180deg);
        }
      }
    }

    .search-courses {
      width: 100%;
      height: 56px;
      border: 1px solid var(--grey);
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;

      .icon {

        margin: 0 16px;
      }

      input {
        background: transparent;
        border: 0;
        width: 100%;
      }
    }

    .modal-category {
      position: absolute;
      background: #fff;
      padding: 24px;
      border: 1px solid #E6F3F1;

      text-transform: uppercase;
      box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);
      border-radius: 8px;
      width: 100%;
      z-index: 5;
      top: 60px;
      left: 0;

      .modal-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 125%;
        margin: 0 0 24px;
        letter-spacing: 0.015em;
        color: #05060B;
      }

      .all-category {
        display: flex;
        flex-flow: column wrap;
        align-content: space-between;
        height: 700px;


        .category {
          width: calc(20% - 10px);
          margin-bottom: 24px;
          background-color: #a1cbfa;

          .category-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            letter-spacing: 0.01em;
            color: #034766;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0 0 16px 0;
            text-transform: uppercase;
          }

          ul {
            li {
              list-style: none;
              font-weight: 400;
              font-size: 14px;
              line-height: 160.5%;
              letter-spacing: 0.01em;

              color: #05060B;
              margin: 0 0 12px;

              .styled-checkbox {
                position: absolute; // take it out of document flow
                opacity: 0; // hide it

                & + label {
                  position: relative;
                  cursor: pointer;
                  padding: 0;
                }

                // Box.
                & + label:before {
                  content: '';
                  margin-right: 10px;
                  display: inline-block;
                  vertical-align: text-top;
                  width: 20px;
                  height: 20px;
                  background: white;
                  border: 2px solid #C9C9C9;
                  border-radius: 4px;
                }

                // Box hover
                &:hover + label:before {
                  background: #EF7936;
                  //border-radius: 4px;
                }

                // Box checked
                &:checked + label:before {
                  background: #EF7936;
                  border: 2px solid #EF7936;
                }

                // Disabled state label.
                &:disabled + label {
                  color: #C9C9C9;
                  cursor: auto;
                }

                // Disabled box.
                &:disabled + label:before {
                  box-shadow: none;
                  background: #C9C9C9;
                }

                // Checkmark. Could be replaced with an image
                &:checked + label:after {
                  content: '';
                  position: absolute;
                  left: 5px;
                  top: 9px;
                  background: white;
                  width: 2px;
                  height: 2px;
                  box-shadow: 2px 0 0 white,
                  4px 0 0 white,
                  4px -2px 0 white,
                  4px -4px 0 white,
                  4px -6px 0 white,
                  4px -8px 0 white;
                  transform: rotate(45deg);
                }

              }
            }
          }
        }
      }

      .form-calculations {
        display: flex;

        .reset-form {
          cursor: pointer;
          background: transparent;
          border: 0;
          font-weight: normal;
          font-size: 16px;
          line-height: 28px;
          margin: 0 22px 0 0;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          color: var(--emerald);

          &:disabled {
            opacity: 0.5;
          }
        }

        .apply-form {
          cursor: pointer;
          padding: 18px 26px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1A89A0;
          border-radius: 100px;
          border: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          text-align: center;
          letter-spacing: 0.01em;
          color: #fff;

          &:disabled {
            opacity: 0.5;
          }
        }
      }

    }
  }

  .selected-category {
    margin: 26px 0;
    display: flex;

    .item {
      font-weight: 400;
      font-size: 14px;
      line-height: 160.5%;
      letter-spacing: 0.01em;
      margin: 0 12px 0 0;
      color: #05060B;
      padding: 10px 12px;
      border: 0.5px solid #C9C9C9;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      .remove-category-item {
        cursor: pointer;
        margin: 0 0 0 12px;
      }
    }

    .clear-all-categories {
      font-weight: 400;
      font-size: 14px;
      line-height: 160.5%;
      letter-spacing: 0.01em;
      color: #fff;
      padding: 12px 16px;
      background: #1A9D86;
      border: 0;
      border-radius: 100px;
      cursor: pointer;
    }
  }

  .scrollbar-container {
    position: relative;
    height: calc(100vh - 220px);
    padding-right: 20px;

    @media (max-width: $tablet) {
      height: auto;
      padding: 0;
      overflow: visible !important;
    }

    .ps__rail-y, .ps__thumb-y {
      width: 4px;
      right: 0;

      &:hover > .ps__thumb-y {
        width: 4px;
      }
    }
  }
}