@import "src/media";

.page-not-found {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: $tablet) {
    padding: 32px 5%;
  }

  .title-page {
    font-weight: 700;
    font-size: 120px;
    line-height: 125%;
    letter-spacing: 0.015em;
    color: var(--grey);
    margin: 0 0 32px 0;

    @media (max-width: $tablet) {
      font-size: 100px;
      margin: 0 0 16px 0;
    }
  }

  .image-not-found {
    margin: 0 0 32px 0;
    max-width: 100%;
  }

  .description-not-found {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #5A5A5A;
    text-align: center;
  }
}