:root {
  --black: #05060B;
  --white: #fff;
  --emerald: #034766;
  --grey: #C9C9C9;
  --grey_dark: #5A5A5A;
  --background: #F9F9F9;
  --blue: #19A3DE;
  --red: #E85B48;
  --marine: #1A89A0;
  --active-marine: #E6F3F1;
  --grey_light: #E5E5E5;
  --blood: #E74C3C;
  --depressed: #373737;
  --dirty: #676A79;
  --mint: #1A9D86;
  --orange: #EF7936;
}