@import "src/media";

.register {
    display: flex;
    width: 100%;

    &__creds {
        flex-grow: 1;
        background-color: var(--white);

        @media (max-width: $tablet) {
            background: var(--background);
        }
    }

    &__deco {
        display: none;

        @media (min-width: 920px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50.83%;
        }
    }

    &__form {
        max-width: 450px;
        margin: 100px auto 0;
        padding: 0 20px;

        @media (min-width: 760px) {
            margin-top: 100px;
        }

        @media (min-width: 1040px) {
            padding: 0;
        }
    }

    &__title {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        letter-spacing: 0.015em;
        text-align: center;
        color: var(--black);
    }

    &__text {
        margin-bottom: 24px;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.01em;
        text-align: center;
        color: var(--grey_dark);
    }

    .header {
        border-bottom: transparent;
    }
}