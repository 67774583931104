.additional-info {
  width: 324px;

  .section-head {
    border-bottom: 0.5px solid #E6F3F1;
    padding: 0 16px;
    display: flex;
    align-items: center;
    height: 70px;

    .section-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;
      letter-spacing: 0.015em;
      color: #05060B;
      display: flex;
      align-items: center;

      .icon {
        margin: 0 12px 0 0;
      }
    }
  }
}