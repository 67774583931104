@import "media";

*,
*:before,
*:after {
    box-sizing: border-box;
}


html {
    height: -webkit-fill-available;
    margin: 0;
    padding: 0;
}

body {
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

input,
button,
select {
    font: inherit;
    outline: none;
}

ul {
    margin: 0;
    padding: 0;

    li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}

input,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

a {
    text-decoration: none;
}

input[type='text'] {
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #05060B;
}

.main-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    min-height: 100vh;
    background: var(--background);

    @media (max-width: $desktop) {
        overflow: hidden;
    }

    .site-container {
        min-height: calc(100vh - 100px);
        height: 100%;
        width: 100%;
        display: flex;

        &.page-account-content {
            .navigation {
                display: none;
            }
        }

        .blur-content {
            position: absolute;
            top: 78px;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff87;
            z-index: 11;
            backdrop-filter: blur(12px);
            opacity: 0;
            visibility: hidden;
            -webkit-transition: linear all 250ms;
            -moz-transition: linear all 250ms;
            -o-transition: linear all 250ms;
            transition: linear all 250ms;

            &.open {
                opacity: 1;
                visibility: visible;
            }

            @media (max-width: $tablet) {
                top: 59px;
            }
        }

        @media (max-width: $desktop) {
            min-height: calc(100vh - 78px);

            &.page-account-content {
                flex-direction: column;

                .navigation {
                    display: flex;
                }
            }
        }

        @media (max-width: $tablet) {
            min-height: calc(100vh - 59px);
        }
    }

    .site-container-not-authorized {
        width: 100%;
        background: var(--grey_light);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: calc(100vh - 100px);
        
        @media (max-width: $tablet) {
            padding: 24px 5%;
            min-height: calc(100vh - 59px);
        }
    }

    .page-content {
        width: calc(100% - 276px);
        padding: 0 40px 0 0;
        background: var(--background);
        -webkit-transition: linear all 250ms;
        -moz-transition: linear all 250ms;
        -o-transition: linear all 250ms;
        transition: linear all 250ms;


        @media (max-width: $desktop) {
            width: 100%;
            padding: 0;
        }


        @media (max-width: $tablet) {
            background: var(--white);
        }
    }
}