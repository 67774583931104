.authorization-modal {


  .authorization-modal__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 125%;
    letter-spacing: 0.015em;
    margin: 0 0 16px;
    color: #05060B;
  }

  .authorization-modal__description {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #05060B;
    margin: 0 0 50px;
    text-align: center;
    max-width: 320px;
  }

  .loading-wrap {
    margin: 0 0 42px;
  }

  .try-again {
    margin: 50px 0 0;

  }

  .authorization-modal-success {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .authorization-modal-fail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}