.certificate-form {

  .modal-form {
    width: 100%;

    .row {
      margin: 0 0 16px;
      position: relative;

      .form-label {
        position: absolute;
        left: 18px;
        top: 18px;
        z-index: 10;
        pointer-events: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: var(--grey);
        transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out;
      }

      .text-input {
        border: 1px solid var(--grey);
        border-radius: 6px;
        width: 100%;
        height: 56px;
        padding: 0 0 0 18px;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: var(--black);
      }

      .text-area {
        width: 100%;
        height: 260px;
        border: 1px solid var(--grey);
        border-radius: 6px;
        padding: 18px 0 0 18px;
        font-size: 16px;
        line-height: 125%;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: var(--black);
      }

      .form-selected-input {
        width: 100%;

        .MuiSelect-icon {
          -webkit-transition: linear all 250ms;
          -moz-transition: linear all 250ms;
          -o-transition: linear all 250ms;
          transition: linear all 250ms;
          right: 20px;
        }
      }

      input:focus,
      textarea:focus {
        outline: none;
        background-size: 100% 100%, 100% 100%;
        transition-duration: 0.3s;
        box-shadow: none;
        border: 1px solid var(--emerald);
      }


      input:focus + label,
      input:not(:placeholder-shown) + label,
      textarea:focus + label,
      textarea:not(:placeholder-shown) + label {
        top: -10px;
        background: var(--white);
        padding: 0 2px;
        font-weight: 300;
        font-size: 12px;
        line-height: 147.5%;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
        color: var(--emerald);
      }

      ::-webkit-input-placeholder {
        opacity: 0;
        color: var(--grey);
      }

      input:focus::-webkit-input-placeholder,
      textarea:focus::-webkit-input-placeholder {
        opacity: 1;
      }

      textarea:not(:focus):not(:placeholder-shown) + label,
      input:not(:focus):not(:placeholder-shown) + label {
        color: var(--black);
      }

      .error {
        font-size: 12px;
        color: var(--red);
        margin: 8px 0 0 18px;
      }

      &.input-error {

        .text-area,
        .text-input {
          border: 1px solid var(--red);
        }

        textarea:not(:placeholder-shown) + label,
        input:not(:placeholder-shown) + label {
          color: var(--red);
        }

      }

      &:last-child {
        margin: 0;
      }

    }

    .submit-wrap {
      margin: 45px 0 0;
      display: flex;
      justify-content: center;

      .button {
        width: 234px;
      }
    }
  }
}