@import "src/media";

.my-certifications-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  //min-height: 570px;

  .request-certificate {
    display: flex;
    flex-direction: column;

    .certificate-image {
      //margin: 46px 0 0;
    }

    .certificate-document{

      .react-pdf__Page__canvas{
        height: 400px !important;
        width: auto !important;
        border-radius: 5px;

        @media (max-width: $tablet) {
          height: 250px !important;
        }
      }

      .react-pdf__Page__textContent{
        height: 400px !important;
        width: auto !important;

        @media (max-width: $tablet) {
          height: 250px !important;
        }
      }


    }

    .request-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 46px 0 0;

      .request {
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        height: 56px;
        padding: 0 26px;
        cursor: pointer;
        background: linear-gradient(105.3deg, #2380AA 0%, #0B978F 100%);
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.01em;
        color: var(--white);
      }
    }
  }

  .certificate-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .form-title {
      font-weight: 600;
      font-size: 32px;
      line-height: 125%;

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.015em;

      color: var(--black);
      margin: 0 0 24px;
    }

    .form-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: var(--grey_dark);
      margin: 0 0 32px;
      max-width: 560px;
    }

    .modal-form {
      max-width: 460px;
      width: 100%;

      .row {
        margin: 0 0 16px;
        position: relative;

        .form-label {
          position: absolute;
          left: 18px;
          top: 18px;
          z-index: 10;
          pointer-events: none;
          font-weight: 400;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: var(--grey);
          transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out;
        }

        .text-input {
          border: 1px solid var(--grey);
          border-radius: 6px;
          width: 100%;
          height: 56px;
          padding: 0 0 0 18px;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: var(--black);
        }

        .text-area {
          width: 100%;
          height: 260px;
          border: 1px solid var(--grey);
          border-radius: 6px;
          padding: 18px 0 0 18px;
          font-size: 16px;
          line-height: 125%;
          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          color: var(--black);
        }


        input:focus,
        textarea:focus {
          outline: none;
          background-size: 100% 100%, 100% 100%;
          transition-duration: 0.3s;
          box-shadow: none;
          border: 1px solid var(--emerald);
        }


        input:focus + label,
        input:not(:placeholder-shown) + label,
        textarea:focus + label,
        textarea:not(:placeholder-shown) + label {
          top: -10px;
          background: var(--white);
          padding: 0 2px;
          font-weight: 300;
          font-size: 12px;
          line-height: 147.5%;
          display: flex;
          align-items: center;
          letter-spacing: 0.015em;
          color: var(--emerald);
        }

        ::-webkit-input-placeholder {
          opacity: 0;
          color: var(--grey);
        }

        input:focus::-webkit-input-placeholder,
        textarea:focus::-webkit-input-placeholder {
          opacity: 1;
        }

        textarea:not(:focus):not(:placeholder-shown) + label,
        input:not(:focus):not(:placeholder-shown) + label {
          color: var(--black);
        }

        .error {
          font-size: 12px;
          color: var(--red);
        }

        &.input-error {

          .text-area,
          .text-input {
            border: 1px solid var(--red);
          }

          textarea:not(:placeholder-shown) + label,
          input:not(:placeholder-shown) + label {
            color: var(--red);
          }

        }

        &:last-child {
          margin: 0;
        }

      }

      .submit-wrap {
        margin: 45px 0 0;
        display: flex;
        justify-content: center;

        .button {
          width: 234px;
        }
      }
    }
  }
}