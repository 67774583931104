.setup-new-avatar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .form-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 125%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.015em;

    color: var(--black);
    margin: 0 0 24px;
  }

  .form-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: var(--grey_dark);
    margin: 0 0 32px;
    max-width: 560px;
  }

  .upload-avatar-error {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: var(--red);
  }

  .file-name{
    margin: 15px 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: var(--emerald);
  }
  .avatar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .upload-wrap {
      width: 328px;
      min-height: 170px;
      border: 1px dashed #8DC5BB;
      border-radius: 12px;
      cursor: pointer;
      margin: 0 0 75px;
      padding: 44px 0 0 0;
      display: flex;
      align-items: center;
      flex-direction: column;

      .icon-upload {
        margin: 0 0 34px;
      }

      .upload-file-description {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #5A5A5A;
      }

    }

    .upload-actions-wrap {

      .button {
        width: 234px;
      }
    }
  }
}