.get-certificate-modal {
  min-height: 550px;

  .get-certificate-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 125%;
    margin: 0 0 20px;
    //display: flex;
    //align-items: center;
    text-align: center;
    letter-spacing: 0.015em;
    color: #05060B;
  }

  .get-certificate-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    margin: 0 0 12px;
    //display: flex;
    //align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #05060B;
  }

  .thank-you-subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5A5A5A;

    .green-color {
      color: #034766;
    }
  }

  .get-certificate-description {
    font-size: 16px;
    line-height: 28px;
    /* or 175% */

    //display: flex;
    //align-items: center;
    text-align: center;
    letter-spacing: 0.01em;

    /* Grey/dark */
    margin: 0 0 30px;
    color: #5A5A5A;
  }

  .request-wrap {
    display: flex;
    justify-content: center;

    .button {
      width: 216px;
    }
  }

}