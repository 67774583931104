@import "src/media";

.navigation {
  background: var(--white);
  min-width: 276px;
  width: 19%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 0.5px solid #E6F3F1;
  padding: 40px 0 40px 40px;
  -webkit-transition: linear all 250ms;
  -moz-transition: linear all 250ms;
  -o-transition: linear all 250ms;
  transition: linear all 250ms;

  .navigation-bottom,
  .navigation-top {
    .menu {

      a {
        padding: 0 0 0 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 125%;
        letter-spacing: 0.01em;
        -webkit-transition: linear background 250ms;
        -moz-transition: linear background 250ms;
        -o-transition: linear background 250ms;
        transition: linear background 250ms;
        height: 64px;
        display: flex;
        align-items: center;
        border-radius: 12px 0 0 12px;
        color: var(--black);
        text-decoration: none;

        @media (max-width: $tablet) {
          font-size: 18px;
        }

        &.active {
          color: var(--white);
          background: var(--emerald);
          border-radius: 12px 0 0 12px;
          font-weight: 500;

          &:hover {
            background: var(--emerald);
            border-radius: 12px 0 0 12px;
          }
        }

        .icon {
          margin: 0 12px 0 0;
        }

        &:hover {
          background: #E6F3F1;
          border-radius: 12px 0 0 12px;
        }
      }
    }
  }

  @media (max-width: $desktop) {
    position: absolute;
    right: -100%;
    top: 78px;
    width: 40%;
    background: #FFFFFF;
    box-shadow: 0 40px 32px rgba(0, 0, 0, 0.12);
    //hack 100vh on mobile devices
    height: calc(100vh - 78px);
    height: calc((var(--vh, 1vh) * 100) + var(--pageOffset) - 78px);
    padding: 0;
    z-index: 12;
    min-width: 310px;
    justify-content: flex-start;
    overflow-y: scroll;

    &.open {
      right: 0;
      padding: 32px 0 0 24px;
    }

    .navigation-bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;


      .user-data {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        .user-avatar {
          border: 2px solid rgba(230, 243, 241, 0.3);
          border-radius: 50%;
          width: 48px;
          height: 48px;
          margin: 0 16px 0 0;


          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .user-full-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          color: var(--black);
          max-width: 124px;
          margin: 0 20px 0 0;
        }

        .arrow {
          -webkit-transition: linear transform 250ms;
          -moz-transition: linear transform 250ms;
          -o-transition: linear transform 250ms;
          transition: linear transform 250ms;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;

        }

        &:hover {
          .arrow {
            -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);

          }

          .profile-menu-list {
            position: absolute;
            opacity: 1;
            visibility: visible;
          }
        }

        .profile-menu-list {
          position: absolute;
          background: #fff;
          box-shadow: 0 8px 17px rgba(0, 0, 0, 0.15);
          border-radius: 0 0 4px 4px;
          opacity: 0;
          visibility: hidden;
          -webkit-transition: linear opacity 250ms;
          -moz-transition: linear opacity 250ms;
          -o-transition: linear opacity 250ms;
          transition: linear opacity 250ms;
          top: -100px;
          left: 0;
          min-width: 234px;
          z-index: 5;

          .profile-navigation {
            display: flex;
            flex-direction: column;

            a, button {
              padding: 0 12px;
              font-weight: 400;
              font-size: 14px;
              line-height: 160.5%;
              letter-spacing: 0.01em;
              color: var(--black);
              text-decoration: none;
              display: flex;
              align-items: center;
              height: 56px;
              background-color: transparent;
              border: 0;
              cursor: pointer;
              border-bottom: 1px solid #E6F3F1;
              -webkit-transition: linear background 250ms;
              -moz-transition: linear background 250ms;
              -o-transition: linear background 250ms;
              transition: linear background 250ms;

              .profile-icon {
                margin: 0 12px 0 4px;
              }

              &:hover {
                background: rgba(83, 166, 131, 0.1);
              }

              .menu-name {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .lang-name {
                  color: #5A5A5A;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet) {
    top: 59px;
    //hack 100vh on mobile devices
    height: calc(100vh - 59px);
    height: calc((var(--vh, 1vh) * 100) + var(--pageOffset) - 59px);
    width: 40%;
  }

  @media (max-width: $mobile-l) {
    width: 65%;
  }

  @media (max-width: 480px) {
    width: 95%;
  }
}