@import "src/media";

.account-navigation {
    background: var(--white);
    min-width: 276px;
    width: 19%;
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid #E6F3F1;
    padding: 52px 0 40px 40px;
    -webkit-transition: linear all 250ms;
    -moz-transition: linear all 250ms;
    -o-transition: linear all 250ms;
    transition: linear all 250ms;

    .user-avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 96px;
            height: 96px;
            border: 2px solid #E6F3F1;
            border-radius: 100px;
            object-fit: cover;
        }
    }

    .change-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 40px;
        cursor: pointer;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .text-change {
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.01em;
            color: #19A3DE;
            margin: 0 0 0 12px;

        }
    }

    .navigation-top {
        .menu {

            a {
                padding: 0 0 0 18px;
                -webkit-transition: linear background 250ms;
                -moz-transition: linear background 250ms;
                -o-transition: linear background 250ms;
                transition: linear background 250ms;
                height: 64px;
                display: flex;
                align-items: center;
                border-radius: 12px 0 0 12px;
                color: var(--black);
                text-decoration: none;
                font-weight: 400;
                font-size: 14px;
                line-height: 160.5%;
                letter-spacing: 0.01em;

                &.active {
                    background: rgba(230, 243, 241, 0.3);

                    &:hover {
                        background: rgba(230, 243, 241, 0.3);
                    }
                }

                .icon {
                    margin: 0 12px 0 0;
                    width: 36px;
                    height: 36px;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #E6F3F1;
                    border-radius: 100px;

                    &.active {
                        background: #1A89A0;

                    }

                }

                &:hover {
                    background: rgba(230, 243, 241, 0.3);

                }
            }
        }
    }

    @media (max-width: $desktop) {
        background: var(--background);
        width: 100%;
        padding: 0;
        flex-direction: column;

        .user-avatar {
            margin: 0 0 16px;
        }

        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 125%;
            display: flex;
            align-items: center;
            letter-spacing: 0.015em;
            color: #05060B;
            background: var(--white);
            padding: 35px 24px 0;

            .back-home {
                display: flex;

                .icon {
                    margin: 0 12px 0 0;
                }
            }
        }

        .account-navigation-wrap {
            display: flex;
            flex-direction: column-reverse;

            .navigation-top {
                background: var(--white);
                padding: 22px 24px 12px;

                .menu {
                    display: flex;
                }

            }

            .user-avatar-wrap {
                margin: 28px 0 0;
            }
        }

        .navigation-top {
            .menu {
                a {
                    padding: 0;
                    border-radius: 12px;
                    width: 30%;
                    justify-content: center;
                }
            }
        }
    }

    @media (max-width: $tablet) {
        .title {
            padding: 32px 5% 28px;
            font-size: 22px;
        }

        .account-navigation-wrap {
            .navigation-top {
                position: relative;
                padding: 0 5% 16px;

                .menu {
                    display: none;
                    position: absolute;
                    width: 90%;
                    background: white;
                    flex-direction: column;
                    border-radius: 8px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);

                    a {
                        width: 100%;
                        height: 48px;
                        padding: 0 26px;
                        border-radius: 0;
                        justify-content: flex-start;

                        .icon {
                            display: none;
                        }
                    }
                }

                .tabs__toggle--open {
                    + .menu {
                        display: flex;
                    }
                }
            }
        }
    }

    .change-photo {
        margin-bottom: 24px;
    }


}