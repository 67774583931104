.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;;
  cursor: pointer;
  border: 0;

  &--gradient {
    height: 56px;
    background: linear-gradient(105.3deg, #2380AA 0%, #0B978F 100%);
    border-radius: 100px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: var(--white);
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;
    padding: 0 26px;

    &:hover {
      background: linear-gradient(105.3deg, rgba(35, 128, 170, 0.8) 0%, rgba(11, 151, 143, 0.8) 100%);
      box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
    }
  }

  &--green {
    height: 56px;
    background: #1A89A0;
    border-radius: 100px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: var(--white);
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;
    padding: 0 26px;

    &:hover {
      background: rgba(26, 137, 160, 0.8);
      box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
    }
  }

  &--border {
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #034766;
    background: #fff;
    border: 1px solid #034766;
    border-radius: 100px;
    -webkit-transition: all 250ms linear;
    -moz-transition: all 250ms linear;
    -o-transition: all 250ms linear;
    transition: all 250ms linear;

    &:hover {
      background: rgba(230, 243, 241, 0.3);
      color: rgba(3, 71, 102, 0.8);
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;

    &:hover {
      box-shadow: none;
    }
  }
}