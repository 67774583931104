@import "src/media";

.header {
  background: var(--white);
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  border-bottom: 0.5px solid #E6F3F1;

  .logo {
    a {
      display: block;
    }
  }

  .user-info {
    display: flex;
    align-items: center;

    .notification-block {
      cursor: pointer;
      width: 44px;
      height: 44px;
      background: rgba(230, 243, 241, 0.3);
      border-radius: 100px;
      margin: 0 48px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      @media (max-width: $tablet) {
        margin: 0 38px 0 0;
      }

      .active-notification {
        position: absolute;
        width: 8px;
        height: 8px;
        top: 12px;
        right: 12px;
        border: 1px solid #fff;
        border-radius: 50%;
        background: #1A9D86;
      }

    }

    .user-data {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      .user-avatar {
        border: 2px solid rgba(230, 243, 241, 0.3);
        border-radius: 50%;
        width: 44px;
        height: 44px;
        margin: 0 16px 0 0;


        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .user-full-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: var(--black);
        max-width: 124px;
        margin: 0 20px 0 0;
      }

      .arrow {
        -webkit-transition: linear transform 250ms;
        -moz-transition: linear transform 250ms;
        -o-transition: linear transform 250ms;
        transition: linear transform 250ms;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

      }

      &:hover {
        .arrow {
          -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg);

        }

        .profile-menu-list {
          position: absolute;
          opacity: 1;
          visibility: visible;
        }
      }

      .profile-menu-list {
        position: absolute;
        background: #fff;
        box-shadow: 0 8px 17px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: linear opacity 250ms;
        -moz-transition: linear opacity 250ms;
        -o-transition: linear opacity 250ms;
        transition: linear opacity 250ms;
        top: 100px;
        right: 0;

        min-width: 234px;
        z-index: 5;

        .profile-navigation {
          display: flex;
          flex-direction: column;

          a, button {
            padding: 0 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 160.5%;
            letter-spacing: 0.01em;
            color: var(--black);
            text-decoration: none;
            display: flex;
            align-items: center;
            height: 56px;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            border-bottom: 1px solid #E6F3F1;
            -webkit-transition: linear background 250ms;
            -moz-transition: linear background 250ms;
            -o-transition: linear background 250ms;
            transition: linear background 250ms;

            .profile-icon {
              margin: 0 12px 0 4px;
            }

            &:hover {
              background: rgba(83, 166, 131, 0.1);
            }

            .menu-name {
              display: flex;
              justify-content: space-between;
              width: 100%;

              .lang-name {
                color: #5A5A5A;
              }
            }
          }
        }
      }
    }


    .menu-toggle {
      position: absolute;
      width: 20px;
      height: 20px;
      display: flex;
      padding: 0;
      align-items: center;
      border: 0;
      right: 40px;
      top: 21px;
      background: transparent;
      outline: 0;
      z-index: 3;

      @media (max-width: $tablet) {
        right: 5%;
        top: 11px;
      }

      &.menu-visible {
        .menu-toggle__inner {
          .menu-toggle__line {
            &:before {
              transform: translate3d(0, 5px, 0) rotate(45deg);
              background-color: #034766;
              top: 2px;
            }

            &:after {
              transform: translate3d(0, 1px, 0) rotate(-45deg);
              background-color: #034766
            }
          }

          .menu-toggle__last-line {
            display: none
          }
        }
      }

      .menu-toggle__inner {
        display: block;
        position: relative;
        width: 20px;

        .menu-toggle__line {
          width: 20px;
          height: 1px;
          border-radius: 1px;
          background: #034766;
          margin: 0 0 5px;

          &:last-child {
            margin: 0
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: 0;
            background-color: #034766;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: 6px;
            bottom: 0;
            background-color: #034766;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform
          }
        }


        .menu-toggle__last-line {
          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            top: 12px;
            background: #034766;
            transition-timing-function: ease;
            transition-duration: .15s;
            transition-property: transform;
          }
        }
      }
    }
  }

  @media (max-width: $desktop) {
    height: 78px;
  }

  @media (max-width: $tablet) {
    height: 59px;
    padding: 0 5%;

    .logo {
      svg {
        width: auto;
        height: 32px;
      }
    }
  }
}

