.empty-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;

  align-items: center;
  justify-content: center;

  .image-wrap {
    margin: 0 0 16px;
  }

  .text-description {
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: var(--grey_dark);
    }
  }

  .btn-wrap {
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.01em;
      color: #FFFFFF;
      background: var(--marine);
      border-radius: 100px;
      padding: 18px 26px;
      -webkit-transition: all 250ms linear;
      -moz-transition: all 250ms linear;
      -o-transition: all 250ms linear;
      transition: all 250ms linear;

      &:hover {
        background: var(--emerald);
      }

    }
  }
}