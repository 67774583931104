@import "src/media";

.page-contact-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 32px;

  @media (max-width: $tablet) {
    padding: 32px 5%;
  }

  .left-column {
    width: 100%;
    max-width: 500px;

    @media (max-width: $tablet) {
      width: 100%;
    }

    .certificate-form {

      .form-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 125%;
        margin: 0 0 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.015em;
        color: #05060B;

        @media (max-width: $tablet) {
          font-size: 22px;
        }
      }

      .form-description {
        font-size: 16px;
        line-height: 28px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #5A5A5A;
        margin: 0 0 22px;
      }
    }


  }

  .right-column {
    display: flex;
    align-items: flex-start;
    width: calc(100% - 500px);
    padding: 140px 0 0 110px;

    @media (max-width: $desktop-l) {
      justify-content: center;
    }

    @media (max-width: $tablet) {
      display: none;
    }
  }


  @media (min-width: 768px) and (max-width: $desktop) {

    .left-column{
      width: 50%;
    }

    .right-column{
      width: 50%;
      padding: 140px 0 0 10px;
    }
  }

}